import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";
import $ from "jquery";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      transition: "fade-in-down",
      title: "Send & Receive money FREELY across borders | Cashwyre",
      metaTags: [
        {
          name: "description",
          content: "Send & Receive money FREELY across borders with Cashwyre.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content: "Send & Receive money FREELY across borders with Cashwyre.",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/AboutView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Save, Send, Receive, Pay With Bitcoin | About Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Send and receive money freely across Africa. Send, Receive, Save & Custody Bitcoin without stress using Cashwyre.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Send and receive money freely across Africa. Send, Receive, Save & Custody Bitcoin without stress using Cashwyre.",
        },
      ],
    },
  },
  {
    path: "/wallet-services",
    name: "WalletServices",
    component: () => import("../views/WalletServices.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Wallet Services | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Save, send, and receive Bitcoin daily, weekly and monthly with Cashwyre.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Save, send, and receive Bitcoin daily, weekly and monthly with Cashwyre.",
        },
      ],
    },
  },
  {
    path: "/payment-services",
    name: "PaymentServices",
    component: () => import("../views/PaymentServices.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Payment Services | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Send tuition fees, make payments, Receive salaries and pay checks, and Send money home to family members with Cashwyre.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Send tuition fees, make payments, Receive salaries and pay checks, and Send money home to family members with Cashwyre.",
        },
      ],
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("../views/PricingView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Pricing | Cashwyre",
      metaTags: [
        {
          name: "description",
          content: "Pricing, Rate, and Limits with using Cashwyre",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content: "Pricing, Rate, and Limits with using Cashwyre",
        },
      ],
    },
  },
  {
    path: "/become-a-partner",
    name: "BecomeLiquidityProvider",
    component: () => import("../views/BecomeLiquidityProvider.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Become A Partner | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
      ],
    },
  },

  {
    path: "/crypto4Cash",
    name: "CryptoForCash",
    component: () => import("../views/CryptoForCash.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Crypto4Cash | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
      ],
    },
  },
  {
    path: "/crypto4Cash-status",
    name: "Crypto4CashStatus",
    component: () => import("../views/Crypto4CashStatus.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Crypto4Cash | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
      ],
    },
  },
  {
    path: "/delete-account",
    name: "DeleteAccount",
    component: () => import("../views/DeleteAccount.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Delete Account | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
      ],
    },
  },
  {
    path: "/delete-account-status",
    name: "DeleteAccountStatusPage",
    component: () => import("../views/DeleteAccountStatusPage.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Delete Account | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Partner with Cashwyre by providing liquidity. Join a pool of other liquidity providers and start earning more with ease.",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "FAQs",
    component: () => import("../views/FaqView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Frequently Asked Questions | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Find answers to questions that are frequently asked by Cashwyre users.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Find answers to questions that are frequently asked by Cashwyre users.",
        },
      ],
    },
  },
  {
    path: "/testimonial",
    name: "Testimonials",
    component: () => import("../views/TestimonialView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Testimonials | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Experience from users of how Cashwyre solves cross border payment problems.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Experience from users of how Cashwyre solves cross border payment problems.",
        },
      ],
    },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Privacy Policy | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "Privacy Policy explaining your privacy rights regarding how we collect, use, share and protect your Personal Data when you visit our website.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "Privacy Policy explaining your privacy rights regarding how we collect, use, share and protect your Personal Data when you visit our website.",
        },
      ],
    },
  },

  {
    path: "/terms",
    name: "TermsConditions",
    component: () => import("../views/TermsView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Terms And Condition | Cashwyre",
      metaTags: [
        {
          name: "description",
          content: "Terms and conditions of services with Cashwyre.",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content: "Terms and conditions of services with Cashwyre.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue"),
    meta: {
      transition: "fade-in-down",
      title: "Contact Us | Cashwyre",
      metaTags: [
        {
          name: "description",
          content: "Contact page for enquiry. Want to become a partner",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content: "Contact page for enquiry. Want to become a partner",
        },
      ],
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/FourZeroFour.vue"),
    meta: {
      transition: "fade-in-down",
      title: "404 - Page Not Found | Cashwyre",
      metaTags: [
        {
          name: "description",
          content:
            "404 Page - Send And Receive Money Freely Across Borders using Bitcoin | Cashwyre",
        },
        {
          name: "keywords",
          content:
            "Cashwyre, Paylink, Bitcoin, BTC, Fintech, USDT, USDC, Border-less, Cross-border Payment",
        },
        {
          property: "og:description",
          content:
            "404 Page - Send And Receive Money Freely Across Borders using Bitcoin | Cashwyre",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  base: process.env.BASE_URL,
  routes,

  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

const DEFAULT_TITLE = "Send And Receive Money Across Borders With Cashwyre";

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  var menuMobile = $("#mobile-menu"),
    mainMenu = $("#main-menu");

  $("body").removeClass("no-scroll");

  if (menuMobile.attr("aria-expanded") === "true") {
    menuMobile.attr("aria-expanded", "false");
    mainMenu.attr("aria-expanded", "false");
    menuMobile.find('[class*="mobile-submenu"]').removeClass("active");
  }
});

export default router;

<template>
  <div id="app">
    <TheNavbarIndex />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <MessageLoadingOverlay />
    <ActionLoadingOverlay />
    <!-- <CalculateInvestmentModal /> -->

    <!-- <transition name="zoomIn">
      <SubscribeModal
        v-if="
          modalActive &&
          ![
            'DeleteAccountStatusPage',
            'DeleteAccount',
            'CryptoForCash',
            'Crypto4CashStatus',
          ].includes(currentSitePage)
        "
      />
    </transition> -->

    <WhatsappDiv />
    <CookiesModal />
    <AlertPop />

    <TheFooter />
  </div>
</template>
<script>
import TheNavbarIndex from "./components/header/TheNavbarIndex.vue";
import TheFooter from "./components/footer/TheFooter.vue";
// import SubscribeModal from "@/components/extra/SubscribeModal";
import CookiesModal from "@/components/extra/CookiesModal";
import AlertPop from "@/components/modal/AlertPop";

import MessageLoadingOverlay from "./components/modal/MessageLoadingOverlay";
import ActionLoadingOverlay from "./components/modal/ActionLoadingOverlay";

import { mapActions, mapState } from "vuex";
import WhatsappDiv from "./components/blocks/WhatsappDiv.vue";

// import "@/assets/css/custom.css";

export default {
  components: {
    MessageLoadingOverlay,
    ActionLoadingOverlay,
    TheFooter,
    TheNavbarIndex,
    // SubscribeModal,
    CookiesModal,
    AlertPop,
    WhatsappDiv,
  },

  computed: {
    ...mapState(["modalActive"]),
  },

  methods: {
    ...mapActions([
      "toggleModalActive",
      "fetchContactInfo",
      "saveCurrencyInfo",
      "getCrypto4CashInfo",
      "saveTestimonies",
      "saveAllData",
    ]),

    // getAppAuthentication: async function () {
    //     this.getAuthentication().then((response) => {
    //         console.log(response);

    //         // let appToken = response.data.value.token;
    //         // if (appToken) {
    //         //     this.axios.defaults.headers.common["Authorization"] =
    //         //         "bearer " + appToken;

    //         //     this.fetchAllInvestment();
    //         //     // this.getFeedbacks({
    //         //     //     userCode: "",
    //         //     //     from: null,
    //         //     //     to: null,
    //         //     //     page: 1,
    //         //     // });
    //         //     // this.getPositiveFeedbacks({
    //         //     //     userCode: "",
    //         //     //     from: null,
    //         //     //     to: null,
    //         //     //     page: 1,
    //         //     // });
    //         // }
    //     });
    // },
  },

  beforeMount() {
    this.saveCurrencyInfo();
    this.getCrypto4CashInfo();
    this.saveTestimonies();
    this.saveAllData();
  },

  mounted() {
    setTimeout(() => {
      this.toggleModalActive(true);
    }, 4000);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Cabin:400,400i,500,500i,600,600i,700,700i&amp;display=swap");

@import url("../public/assets/css/custom.css");
@import url("../public/assets/css/custom2.css");
@import url("../public/assets/css/themify-icons.css");

:root {
  --orangeLight: #f89a02;
  --orangeDark: #c57705;
  --black1: #000;
  --black2: #161719;
  --white: #fff;
  --offWhite: #f8f8f8;
  --grey: #cec7c7;
  --grey2: #a3a3a3;
  --grey2-transp: #ababab55;

  // --- gradients ---
  // F8E402, C57705
  // background: linear-gradient(180deg, #F89A02 0%, #C57705 46.35%, rgba(197, 119, 5, 0.81) 78.12%);
  --errorColor: #ff3232;
}

#app,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  background: var(--black1);
}

/* scrollbar */
body::-webkit-scrollbar,
body::-webkit-scrollbar-track,
body::-webkit-scrollbar-thumb {
  transition: var(--transition);
}

body::-webkit-scrollbar {
  width: 13px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: var(--black1);
  // border-radius: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.97);
  }
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: var(--black2);
  // border-radius: 10px;

  &:hover {
    background: var(--grey2);
  }
}

::selection {
  background-color: var(--orangeDark);
  color: var(--white);
}

::-moz-selection {
  background-color: var(--orangeDark);
  color: var(--white);
}

// page transition
.fade-enter-active,
.fade-leave-active {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /* transform: scale(0.9); */
}

.page-enter,
.page-leave-to {
  opacity: 0;
  -o-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.page-enter-active,
.page-leave-active {
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-enter-to,
.page-leave {
  opacity: 1;
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

// components transitions -----
// zoom in
.zoomIn-enter-active,
.zoomIn-leave-active {
  -o-transition: 0.2s ease-in-out all;
  -moz-transition: 0.2s ease-in-out all;
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
}

.zoomIn-enter-from,
.zoomIn-leave-to {
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

a {
  text-decoration-skip-ink: auto;
}

i,
a[class|="simple"]:hover {
  color: var(--orangeDark);
}

[class|="sectiontitle-element"] div[class|="toptitle"],
[class|="sectiontitle-element"] span[class|="toptitle"] {
  color: var(--orangeLight);
  text-transform: capitalize;
}

[class|="btn"][class*="-big"][class*="-light"][class*="-border"],
[class|="btn"][class*="-lg"][class*="-light"][class*="-border"],
[class|="btn"][class*="-md"][class*="-light"][class*="-border"],
[class|="btn"][class*="-medium"][class*="-light"][class*="-border"],
[class|="btn"][class*="-sm"][class*="-light"][class*="-border"],
[class|="btn"][class*="-small"][class*="-light"][class*="-border"],
[class|="btn"][class*="-xl"][class*="-light"][class*="-border"] {
  text-transform: capitalize;
}

[class|="btn"][class*="-big"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-lg"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-md"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-medium"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-sm"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-small"][class*="-light"][class*="-border"]:hover,
[class|="btn"][class*="-xl"][class*="-light"][class*="-border"]:hover {
  color: var(--orangeLight);
}

/* ------- 
for PageHeaderBannerless and NotFound 
------- */

#header.uniqueHeaderClass {
  box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%);
}

#header.uniqueHeaderClass[class*="transparent"]
  nav.main-navigation
  #main-menu
  [class|="menu-button"]
  .bar {
  background-color: var(--black2);
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  [class|="menu-button"]
  .bar {
  background-color: var(--white);
}

#header.uniqueHeaderClass
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a {
  color: var(--black2);
}

#header.uniqueHeaderClass
  nav.main-navigation
  #main-menu
  .cta
  a[class|="btn"][class*="border"] {
  border-color: var(--black2);
  color: var(--black2);
}

header[class*="transparent"][class*="moove"] {
  box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%);
}

header[class*="transparent"]:before {
  // background-color: transparent;
  background-color: var(--black2);
}

header[class*="transparent"][class*="moove"]:before {
  // background-color: var(--black2);
  background-color: rgba(22, 23, 25, 0.935) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 15%);
}

// fix dropdown submenu open on mobile only
header
  nav.main-navigation
  #mobile-menu
  [class|="mobile-wrap"]
  [class*="main-links"]
  > ul
  li[class|="dropdown-parent"][aria-expanded="false"]
  [class*="dropdown-inner"] {
  display: none;
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a,
#header.uniqueHeaderClass
  nav.main-navigation
  #main-menu
  .menu-holder
  ul
  li[class*="dropdown-parent"][class*="icon"]
  > a:after {
  // color: var(--black2);
  color: rgba(255, 255, 255, 0.7);
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  .menu-holder
  ul
  li[class*="dropdown-parent"][class*="icon"]
  > a:after {
  color: var(--white);
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a:hover {
  opacity: 1;
  color: var(--white);
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  .cta
  a[class|="btn"][class*="border"] {
  border-color: var(--white);
  color: var(--white);
}

header[class*="transparent"]
  nav.main-navigation
  #main-menu
  .menu-holder
  ul
  li
  a {
  padding-top: 15px;
  padding-bottom: 15px;
}

header
  nav.main-navigation
  #main-menu
  .menu-holder
  ul
  li[class*="dropdown-parent"]
  [class|="dropdown-inner"]
  ul
  li
  a {
  padding-top: 0;
  padding-bottom: 0;
}

header[class*="transparent"]
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a.active,
header[class*="transparent"]
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a.active-item {
  -moz-text-decoration: none;
  text-decoration: none;
  border-bottom-width: 2px;
  border-style: solid;
}

header[class*="transparent"][class*="moove"]
  nav.main-navigation
  #main-menu
  .menu-holder
  > ul
  > li
  > a.active {
  color: var(--white);
  // border-color: var(--white);
}

header nav.main-navigation #main-menu .menu-holder ul li a {
  &:hover,
  &.active {
    color: var(--orangeDark);
    text-decoration: none;
  }
}

header[class*="transparent"]
  nav.main-navigation
  #main-menu
  .menu-holder
  ul
  li[class*="dropdown-parent"][class*="icon"]
  > a:after {
  opacity: 0.7;
  top: 50%;
  -o-transform: translateY(-35%);
  -ms-transform: translateY(-35%);
  -moz-transform: translateY(-35%);
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
}

header nav.main-navigation #logo a.navbar-brand {
  max-width: 120px !important;

  & img {
    max-height: initial !important;
  }
}

header nav.main-navigation #main-menu .menu-holder + [class|="cta"],
header nav.main-navigation #main-menu .menu-holder + [class|="menu-button"],
header nav.main-navigation #main-menu .menu-holder + [class|="shortlinks"],
header nav.main-navigation #top-menu .menu-holder + [class|="cta"],
header nav.main-navigation #top-menu .menu-holder + [class|="menu-button"],
header nav.main-navigation #top-menu .menu-holder + [class|="shortlinks"],
header nav.top-navigation #main-menu .menu-holder + [class|="cta"],
header nav.top-navigation #main-menu .menu-holder + [class|="menu-button"],
header nav.top-navigation #main-menu .menu-holder + [class|="shortlinks"],
header nav.top-navigation #top-menu .menu-holder + [class|="cta"],
header nav.top-navigation #top-menu .menu-holder + [class|="menu-button"],
header nav.top-navigation #top-menu .menu-holder + [class|="shortlinks"] {
  margin-left: 4rem;
  margin-right: 0.5rem;
}

[class|="sectiontitle-element"] h2,
[class|="sectiontitle-element"] h3 {
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.toptitle {
  position: relative;
  // padding-left: 1rem;

  // &::before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     height: 2px;
  //     width: 5px;
  //     margin: auto;
  //     margin-left: -10px;
  //     background: var(--orangeLight);
  //     border-radius: 5px;
  // }
}

.find_out_more_container a {
  border-color: var(--orangeDark) !important;
  color: var(--orangeDark) !important;
  text-transform: capitalize;

  &:hover {
    background: var(--orangeDark) !important;
    color: var(--white) !important;
  }
}

// carousels
// --------countries
.country-carousel .owl-carousel {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.country-carousel .owl-carousel .owl-item {
  height: 68px !important;
  width: 98px !important;
}

.country-carousel .owl-carousel .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem !important;
}

// --------quotes
#quotes .owl-carousel .owl-dots {
  position: absolute;
  left: -2.875rem;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  transform: translateY(-1.5rem);
}

#quotes .owl-theme .owl-dots .owl-dot span {
  height: 8px !important;
  width: 8px !important;
}

#quotes .owl-carousel .owl-dots .owl-dot.active span {
  height: 18px !important;
  width: 8px !important;
}

#quotes .owl-carousel .owl-dots .owl-dot,
#quotes .owl-carousel .owl-dots .owl-dot.active,
#quotes .owl-carousel .owl-dots .owl-dot.active span {
  outline: none !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--orangeLight) !important;
}

.owl-carousel {
  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ------- faqs style -------
#faqs {
  .sectiontitle-element {
    max-width: 700px;

    h2 {
      color: var(--white);
    }

    p {
      color: var(--grey2);
    }
  }

  .faq-wrapper {
    .faq-block {
      margin: 50px 0 0 0;
      /* padding: 0 16px; */

      .block-header {
        display: flex;
        border-bottom: 1px solid var(--orangeDark);
        padding-bottom: 5px;
        cursor: pointer;

        &:hover h3,
        h3:hover {
          color: var(--orangeDark);
          -o-transition-duration: 0.4s;
          -moz-transition-duration: 0.4s;
          -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
        }

        h3 {
          font-size: 1.125rem;
          font-weight: 500;
          color: var(--white);
          margin-left: 12px;
        }

        .header-icon {
          height: 24px;
          width: 24px;
          min-width: 24px;
          background: var(--orangeDark);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-transition: all 0.4s;
          transition: all 0.4s;

          i {
            color: #fcfcfc;
            font-size: 12px;
          }
        }

        &.is-active {
          .header-icon {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          h3 {
            color: var(--orangeDark);
          }
        }
      }

      .block-body {
        padding: 1.25rem 10px 0;
        display: none;

        &.show {
          display: block !important;
        }

        h3,
        h4 {
          font-size: 1rem;
          color: var(--white);
        }

        p {
          color: var(--grey2);

          .mt-3 {
            margin-top: 1rem;
          }

          b {
            font-weight: 700;
            color: var(--grey2);
          }
        }

        a {
          text-decoration: underline;
          color: var(--orangeDark);

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .find_out_more_container {
      margin-top: 80px;
    }
  }

  ol,
  ul,
  h3,
  h4 {
    margin-bottom: 1rem;
  }

  ol,
  ul {
    list-style-type: circle;
    padding-left: 1.75rem;
  }

  li {
    font-family: "Cabin", sans-serif;
    color: var(--grey2);
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    /* margin-bottom: 1rem; */

    &::marker {
      color: var(--grey2);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  // @media screen and (max-width: 426px) {
  //     ol,
  //     ul {
  //         padding-left: 1.5rem;
  //     }
  // }
}

// ------- privacy policy style -------
// ------- terms and conditions style -------
#privacy-policy,
#terms-conditions {
  // .privacy-policy {
  h4,
  h5 {
    color: var(--white);
  }

  p,
  li,
  li:before {
    color: var(--grey2);
  }

  ul {
    margin-bottom: 0;
  }

  [class|="separator-element"] {
    border-top-color: var(--grey2-transp);
  }
  // }
}

// ------- pricing table -------
#pricing-table {
  padding-top: 50px;
  padding-bottom: 50px;

  [class|="sectiontitle-element"] {
    h2 {
      color: var(--white);
    }
  }

  .table {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    // padding: 20px 30px;
    padding: 40px 30px 0px;
    margin: auto;
    background: transparent;
    border: 1px solid var(--black2);
    border-radius: 20px;

    & * {
      line-height: initial;
      vertical-align: middle;
    }

    // .category {
    //     padding: 25px 5px;
    //     -o-transition: 0.3s ease;
    //     -moz-transition: 0.3s ease;
    //     -webkit-transition: 0.3s ease;
    //     transition: 0.3s ease;

    //     &:not(:last-child) {
    //         border-bottom: 1px solid var(--black2);
    //     }

    //     &:hover {
    //         background: var(--black2);
    //     }

    //     tr > * {
    //         padding: 0 5px;
    //     }
    // }

    .title {
      display: flex;
      font-size: 18px;
      color: var(--white);
      // padding-top: 5px;
      // padding-bottom: 5px;
      // border-top: 1px solid var(--black2);
      // border-bottom: 1px solid var(--black2);
    }

    .subtitle {
      display: flex;
      font-size: 16px;
      color: var(--grey2);
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--black2);
      }
    }

    .head {
      flex-basis: 50%;
      padding-right: 5px;
    }

    .description {
      flex-basis: 50%;
      text-align: right;
    }

    @media screen and (max-width: 767px) {
      padding: 15px;
    }

    @media screen and (max-width: 426px) {
      .category {
        padding-left: 0;
        padding-right: 0;
      }

      .title {
        .head {
          flex-basis: 100%;
        }

        .description {
          flex-basis: 0%;
        }
      }

      .subtitle {
        .head {
          flex-basis: 45%;
        }

        .description {
          flex-basis: 55%;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  header
    nav.main-navigation
    #mobile-menu
    [class|="mobile-wrap"]
    [class*="main-links"]
    > ul
    li[class|="dropdown-parent"]
    svg {
    display: none !important;
  }

  header
    nav.main-navigation
    #mobile-menu
    [class|="mobile-wrap"]
    [class*="main-links"]
    > ul
    li[class|="dropdown-parent"]
    [class*="dropdown-inner"] {
    padding-left: 10px;

    span {
      font-size: 1.25rem;
      padding: 0;

      .service-desc {
        font-size: 1rem;
        color: var(--grey2);
      }
    }
  }
}

header [class|="wrap-header"][class*="padding-lg"] {
  padding: 1.25rem 4%;
}

@media screen and (min-width: 1024px) {
  header [class|="wrap-header"][class*="padding-lg"] {
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media screen and (min-width: 1440px) {
  header [class|="wrap-header"][class*="padding-lg"] {
    padding-left: 135px;
    padding-right: 135px;
  }
}

@media screen and (max-width: 769px) {
  #quotes .owl-carousel .owl-dots {
    margin: 0;
    transform: translateY(-2.5rem);
  }
}

@media screen and (max-width: 426px) {
  header [class|="top-header"][class*="padding-lg"],
  header [class|="wrap-header"][class*="padding-lg"] {
    padding-left: 0px;
    padding-right: 0px;
  }

  header nav.main-navigation #logo a.navbar-brand img {
    max-height: 65px !important;
  }

  header nav.main-navigation #main-menu .menu-holder + [class|="cta"],
  header nav.main-navigation #main-menu .menu-holder + [class|="menu-button"],
  header nav.main-navigation #main-menu .menu-holder + [class|="shortlinks"],
  header nav.main-navigation #top-menu .menu-holder + [class|="cta"],
  header nav.main-navigation #top-menu .menu-holder + [class|="menu-button"],
  header nav.main-navigation #top-menu .menu-holder + [class|="shortlinks"],
  header nav.top-navigation #main-menu .menu-holder + [class|="cta"],
  header nav.top-navigation #main-menu .menu-holder + [class|="menu-button"],
  header nav.top-navigation #main-menu .menu-holder + [class|="shortlinks"],
  header nav.top-navigation #top-menu .menu-holder + [class|="cta"],
  header nav.top-navigation #top-menu .menu-holder + [class|="menu-button"],
  header nav.top-navigation #top-menu .menu-holder + [class|="shortlinks"] {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 414px) {
  header nav.main-navigation #main-menu [class|="cta"],
  header nav.main-navigation #top-menu [class|="cta"],
  header nav.top-navigation #main-menu [class|="cta"],
  header nav.top-navigation #top-menu [class|="cta"] {
    display: none;
  }

  header nav.main-navigation #mobile-menu [class|="mobile-wrap"] {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .shown_mobile_only {
    display: flex !important;
    margin-top: 1.5rem;
  }

  // .shown_mobile_only a:last-child {
  //     margin-left: 0.5rem;
  // }
}
</style>

/* eslint-disable */
import router from "../router";

// PAGE LOADING STATUS
export const checkCurrentRoute = () => {
  return router.history.current.meta.requiresAuth;
};

export const gtYears = () => {
  let now = new Date().getUTCFullYear();
  let years = Array(now - (now - 100))
    .fill("")
    .map((v, idx) => now - idx);
  // console.log(JSON.stringify(years));

  return years;
};

export const getCurrentYear = () => {
  let date = new Date();
  return date.getFullYear();
};
export const getCurrentdate = () => {
  var d = new Date();
  var month = new Array();
  var date = d.getDate();
  var year = d.getFullYear();

  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sept";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  var m = month[d.getMonth()];

  let currentD = date + "-" + m + "-" + year;

  return currentD;
};

// ########################3       GENERAL        #################################

export const cashwyreURL = (state) => {
  return state.cashwyreURL;
};

export const registrationInfo = (state) => {
  return state.registrationInfo;
};

export const contactInfo = (state) => {
  return state.contactInfo;
};

export const AppId = (state) => {
  return state.AppId;
};

export const RequestId = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const currentSitePage = (state) => {
  return state.currentSitePage;
};

export const bannerSettings = (state) => {
  return state.bannerSettings;
};

export const getCountries = (state) => {
  return state.countries;
};

export const getCountryCurrencies = (state) => {
  return state.countryCurrencies;
};

export const getCurrencyInfo = (state) => {
  return state.currencyInfo;
};

export const getAllGlobalCountries = (state) => {
  return state.allGlobalCountries;
};

export const calculationResult = (state) => {
  return state.calculationResult;
};

export const getIsCalculating = (state) => {
  return state.isCalculating;
};

export const getAllFaqs = (state) => {
  if (state.allData) {
    return (state.allFaqs = state.allData.websiteFAQ);
  }
};

export const getCrypto4CashFAQ = (state) => {
  if (state.allData) {
    return state.allData.websiteCrypto4CashFAQ;
  }
};

export const getAllTermsAndConditions = (state) => {
  if (state.allData) {
    return (state.allTermsAndConditions = state.allData.websiteTnA);
  }
};

export const getAllPrivacyData = (state) => {
  if (state.allData) {
    return (state.allPrivacyData = state.allData.websitePrivacy);
  }
};

export const getPricingTableData = (state) => {
  if (state.allData) {
    return (state.pricingTableData = state.allData.websitePrizeTable);
  }
};

export const getAllCounterItems = (state) => {
  if (state.allData) {
    const data = {
      supportedCountries: state.allData.websiteSupportedCountries,
      activePartners: state.allData.websitePartnerCount,
      successfulTransactions: state.allData.websiteSuccessfulTransactions,
      activeUsers: state.allData.websiteActiveUsers,
    };

    return (state.allCounterItems = data);
  }
};

export const getTestimonies = (state) => {
  return state.allTestimonies;
};

export const getIsCookiesSet = (state) => {
  return state.isCookiesSet;
};

export const allInvestments = (state) => {
  return state.allInvestments;
};

// export const positiveFeedBacks = (state) => {
//   return state.positiveFeedBacks;
// };

// export const feedBacks = (state) => {
//   return state.feedBacks;
// };

// ########################3           LOADERS        #################################

export const pageLoading = (state) => {
  return state.pageLoading;
};

export const pageProcessing = (state) => {
  return state.pageProcessing;
};

// ########################3           MODALS        #################################

export const alertModalMessage = (state) => {
  return state.alertModalMessage;
};

export const alertModalType = (state) => {
  return state.alertModalType;
};

export const alertModalStatus = (state) => {
  return state.alertModalStatus;
};

export const alert2 = (state) => {
  return state.alert2;
};

export const actionProcessing = (state) => {
  return state.actionProcessing;
};

export const modalLoading = (state) => {
  return state.modalLoading;
};

export const antiPhizingModal = (state) => {
  return state.antiPhizingModal;
};

// export const calculateInvestmentModal = (state) => {
//   return state.calculateInvestmentModal;
// };

// ########################3           NOTIFICATIONS        #################################

export const alertStatus = (state) => {
  return state.alertStatus;
};

export const alertType = (state) => {
  return state.alertType;
};

export const alertMessage = (state) => {
  return state.alertMessage;
};

export const verifyPayload = (state) => {
  return state.verifyPayload;
};

export const verifyPayloadWithMessage = (state) => {
  return state.verifyPayloadWithMessage;
};

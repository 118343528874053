<template>
    <div class="page-header--image" data-aos="fade-in" data-overlay="">
        <div
            class="imagebg"
            v-if="bannerSettings.imageUrl"
            :style="`background-image: url(${bannerSettings.imageUrl})`"
        ></div>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <h1
                            data-aos="fade-in"
                            class="banner-title"
                            v-if="bannerSettings.title"
                        >
                            {{ bannerSettings.title }}
                        </h1>
                        <p
                            data-aos="fade-in"
                            data-aos-delay="200"
                            class="banner-description"
                            v-if="bannerSettings.description"
                        >
                            {{ bannerSettings.description }}
                        </p>

                        <a
                            href="#download-app"
                            class="btn--big--round"
                            v-if="bannerSettings.buttonUrl"
                        >
                            {{ bannerSettings.buttonUrl }}
                        </a>
                    </div>

                    <div class="list-element--text--breadcrumbs--arrow--light">
                        <ul>
                            <li v-if="bannerSettings.prevLinkText">
                                <router-link
                                    :to="{ name: bannerSettings.prevLinkText }"
                                    >{{
                                        bannerSettings.prevLinkText
                                    }}</router-link
                                >
                            </li>
                            <li v-if="bannerSettings.currentLinkText">
                                {{ bannerSettings.currentLinkText }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
[class|="page-header"][class*="image"] {
    min-height: 60vh;
    min-width: 100vw;
    padding-top: 200px;
    padding-bottom: 100px;
}

[class|="page-header"] [class*="imagebg"] {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    z-index: 0;
}

[class|="page-header"][data-overlay]:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
}

[class|="page-header"] {
    .sectiontitle-element--center {
        z-index: 2;

        .banner-title {
            color: var(--white);
        }

        .banner-title + * {
            margin-top: 30px;
            margin-bottom: 0;
        }

        .banner-description {
            color: var(--grey2);
        }

        a {
            background: linear-gradient(
                to bottom right,
                var(--black2),
                var(--black2)
            );
            color: var(--grey);

            &:hover {
                opacity: 0.7;
            }
        }
    }

    [class|="list-element"][class*="text"][class*="breadcrumbs"] {
        left: 0;
        right: 0;
        text-align: center;
        z-index: 2;

        ul {
            li {
                text-transform: capitalize;

                a {
                    color: var(--orangeDark);
                }

                &:last-of-type {
                    font-weight: normal;
                    color: var(--grey2);
                }
            }
        }
    }
}
</style>

<template>
  <div
    :class="[
      currentSitePage == 'Home',
      [
        'Services',
        'WalletServices',
        'PaymentServices',
        'Pricing',
        'About',
        'Contact',
        'FAQs',
        'Testimonials',
        'PrivacyPolicy',
        'TermsConditions',
        'CryptoForCash',
        'Crypto4CashStatus',
        'DeleteAccount',
        'DeleteAccountStatusPage',
        'BecomeLiquidityProvider',
      ].includes(currentSitePage),
      ['NotFound'].includes(currentSitePage),
    ]"
  >
    <!-- <Navbar :currentSitePage="currentSitePage" /> -->

    <Navbar
      v-if="
        [
          'Home',
          'Services',
          'WalletServices',
          'PaymentServices',
          'Pricing',
          'About',
          'Contact',
          'FAQs',
          'Testimonials',
          'PrivacyPolicy',
          'TermsConditions',
          'BecomeLiquidityProvider',
          'CryptoForCash',
          'Crypto4CashStatus',
          'DeleteAccount',
          'DeleteAccountStatusPage',
          'NotFound',
        ].includes(currentSitePage)
      "
      v-once
    />

    <!-- <Navbar2 v-if="currentSitePage == 'NotFound'" v-once /> -->

    <HomeBanner v-if="currentSitePage == 'Home'" v-once />

    <PageHeader
      v-if="
        [
          'Services',
          'WalletServices',
          'PaymentServices',
          'Pricing',
          'About',
          'Contact',
          'FAQs',
          'Testimonials',
          'PrivacyPolicy',
          'TermsConditions',
          'BecomeLiquidityProvider',
        ].includes(currentSitePage)
      "
      v-once
    />

    <NotFound v-if="currentSitePage == 'NotFound'" v-once />
  </div>
</template>
<script>
import "@/mixins";
import Navbar from "./TheNavbar.vue";
// import Navbar2 from "./TheNavbar2.vue";
import HomeBanner from "../banners/HomeBanner.vue";
import PageHeader from "../banners/PageHeader.vue";
//import PageHeaderBannerless from "../banners/PageHeaderBannerless.vue";
import NotFound from "../banners/NotFound.vue";

export default {
  components: {
    Navbar,
    // Navbar2,
    HomeBanner,
    PageHeader,
    //PageHeaderBannerless,
    NotFound,
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>

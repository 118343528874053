<template>
    <section id="how-it-works" data-padding="sm">
        <div class="container">
            <!-- data-padding="xstop" -->
            <div data-aos="fade-up" class="row pb-40">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <span class="toptitle">How to begin</span>
                        <h2>Get started in four easy steps!</h2>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div
                    v-for="(step, index) in allSteps"
                    :key="index"
                    data-aos="fade-up"
                    :data-aos-delay="(index + 1) * 80"
                    class="col-12 col-md-6 col-lg-3"
                >
                    <div class="icon-element--round--box--border">
                        <div class="info">
                            <div class="icon">
                                <i :class="`icon ${step.icon}`"></i>
                                <div class="step-count">{{ index + 1 }}</div>
                            </div>

                            <div class="text">
                                <h6>{{ step.title }}</h6>
                                <p>{{ step.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="find_out_more_container text-center"
                data-padding="xstop"
            >
                <a
                    href="#download-app"
                    rel="noopener noreferrer"
                    class="btn--big--border--light--round"
                    >Create a free account</a
                >
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            allSteps: [
                {
                    icon: "ti-user",
                    title: "Signup in Minutes",
                    description:
                        "Sign up on our Web App or Mobile App. Available for Android and IOS devices.",
                },
                {
                    icon: "ti-lock",
                    title: "Identity verification",
                    description:
                        "Provide extra basic information to assist us with verifying your details.",
                },
                {
                    icon: "ti-wallet",
                    title: "Fund Your Wallet",
                    description:
                        "Fund your wallet through FIAT or stable coins (USDT or USDC).",
                },
                {
                    icon: "ti-stats-up",
                    title: "Start transacting",
                    description:
                        "Buy BTC, Sell BTC, Receive BTC, Send BTC as well as stable coins.",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
#how-it-works {
    .sectiontitle-element--center,
    .sectiontitle-element {
        h2,
        h3 {
            color: var(--white);
        }

        h3 {
            font-size: 28px;
            text-transform: capitalize;
        }

        p {
            color: var(--grey2);
        }
    }

    .sectiontitle-element p,
    .list-element ul li,
    .list-element ul li:before {
        font-size: 1rem;
        color: var(--grey2);
    }

    [class|="icon-element"][class*="-box"] {
        .info {
            // padding: 0 1.5rem;
            padding: 0;

            .icon {
                i {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 4rem !important;
                    color: var(--black2);
                }

                .step-count {
                    position: absolute;
                    top: 13px;
                    right: 0;
                    width: 2.125rem;
                    height: 2.125rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.875rem;
                    font-weight: 600;
                    background: var(--orangeLight);
                    color: #fff;
                    border-radius: 50%;
                }
            }

            .text {
                justify-content: center;
                text-align: center;
                opacity: 1 !important;

                h6 {
                    font-size: 1.25rem !important;
                    text-transform: capitalize;
                    color: var(--white);
                }

                p {
                    color: var(--grey2);
                    margin-bottom: 0;
                    padding-top: 10px;
                }
            }
        }

        &:hover {
            background: linear-gradient(
                to bottom right,
                var(--black2),
                var(--black2)
            );

            // & > .info .text {
            //     p,
            //     h6 {
            //         color: var(--black2);
            //     }
            // }
        }
    }
}

[class|="icon-element"][class*="-box"][class*="-border"] {
    border: none;
}

[class|="icon-element"][class*="-round"] [class|="icon"],
[class|="icon-element"][class*="-square"] [class|="icon"] {
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: rgb(225, 240, 230);
}
</style>

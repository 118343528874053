/* eslint-disable */
export default {
  // LOADING
  pageLoading: {
    status: false,
    message: "",
    payload: null,
  },

  actionProcessing: false,
  modalLoading: false,
  pageProcessing: false,

  // MODALS
  alertModalStatus: false,
  alertModalType: "",
  alertModalMessage: "",
  alertStatus: false,
  alertType: "",
  alertMessage: "",

  // calculateInvestmentModal: {
  //   status: false,
  //   payload: null,
  // },

  // GLOBAL PROPERTIES
  AppId: "e14a0642-6b0c-4032-8ae6-96fee7e58fbb",
  RequestId: "001web001web" + Math.floor(Math.random() * 10),

  registrationInfo: null,
  countryInfo: null,
  contactInfo: null,

  allInvestments: null,
  // positiveFeedBacks: null,
  // feedBacks: null,

  allTestimonies: null,

  countries: null,
  countryCurrencies: null,
  currencyInfo: null,
  cryto4CashInfo: null,
  calculationResult: null,
  isCalculating: null,

  allGlobalCountries: null,

  allData: null,
  allFaqs: null,
  allTermsAndConditions: null,
  allPrivacyData: null,
  pricingTableData: null,
  allCounterItems: null,

  cashwyreURL: "https://cashwyreservice.azurewebsites.net/api",
  countryFlagUrl: "https://countryflagsapi.com/png",

  currentSitePage: "",
  bannerSettings: {
    title: "",
    description: "",
    buttonUrl: null,
    prevLinkText: null,
    currentLinkText: null,
    imageUrl: null,
  },

  modalActive: null,
  cookiesActive: null,
  isCookiesSet: null,
  alert2: {
    status: false,
    type: "",
    message: "",
  },
};

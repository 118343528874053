<template>
    <div id="footer-bottom" class="row">
        <div class="col-12 down border">
            <div
                class="wrapper justify-content-md-between align-items-md-center"
            >
                <div class="text">
                    <p>
                        Copyright &copy; <span id="footer-year"></span> Cashwyre
                    </p>
                </div>

                <div class="link">
                    <div class="list-element--text--inline">
                        <ul>
                            <li>
                                <router-link :to="{ name: 'PrivacyPolicy' }"
                                    >Privacy Policy</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'TermsConditions' }"
                                    >Terms &amp; Condition</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let footerYear = document.getElementById("footer-year");

        if (footerYear) {
            let fullCopyrightYear;
            let date = new Date();
            let currentYear = date.getFullYear();
            const startYear = 2023;

            currentYear > startYear
                ? (fullCopyrightYear = `${startYear} - ${currentYear}`)
                : (fullCopyrightYear = currentYear);

            footerYear.innerHTML = fullCopyrightYear;
        }
    },
};
</script>

<style lang="scss" scoped>
#footer-bottom {
    .wrapper {
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 425px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .text,
    ul li {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    p,
    a {
        font-size: 15px;
        color: var(--grey2);
    }

    a:hover {
        color: var(--offWhite);
        opacity: 1;
    }

    & .border:after {
        background-color: var(--grey2-transp);
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",attrs:{"data-padding":"smtop"}},[(_vm.countries !== null && _vm.countries.length > 0)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-in"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"sectiontitle-element"},[_c('h3',[_vm._v(" Direct payments into Bank Accounts/Momo Wallets are available in these ["+_vm._s(_vm.countries.length)+"] countries. Others will be added soon. ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('carousel',{staticClass:"country-carousel",attrs:{"items":2,"autoplay":true,"nav":false,"dots":true,"margin":45,"stagePadding":10,"autoHeight":true,"responsive":{
                        540: { items: 3 },
                        768: {
                            items: 5,
                            dots: true,
                            stagePadding: 0,
                        },
                        1200: {
                            items: 8,
                            dots: false,
                            stagePadding: 0,
                        },
                    }}},_vm._l((_vm.countries),function(country,index){return _c('div',{key:index,staticClass:"logo-element"},[_c('img',{attrs:{"src":country.logo,"alt":`${country.name} logo`},on:{"mouseover":_vm.imageHovered}}),_c('h4',{staticClass:"country-back",attrs:{"aria-label":country.name},on:{"mouseout":_vm.textNotHovered,"click":_vm.imageHovered}},[_vm._v(" "+_vm._s(country.name)+" ")])])}),0)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
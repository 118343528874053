<template>
    <section id="what-we-do" data-padding="mdtop">
        <div class="container">
            <div data-aos="fade-up" class="row pb-40">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <h2>Become a Partner</h2>
                        <p>
                            Cashwyre offers you the opportunity to partner with
                            us by providing liquidity in any currency of choice
                            and in the process, earn as a result. Join the pool
                            of other partners and start earning more. To learn
                            more, follow the link below
                        </p>
                    </div>
                </div>
            </div>

            <div class="row align-items-lg-center">
                <div
                    data-aos="fade-in"
                    data-aos-delay="200"
                    class="
                        col-12 col-md-6 col-lg-4
                        left
                        order-2 order-md-1 order-lg-0
                    "
                >
                    <div class="sectiontitle-element">
                        <h3>Provide Liquidity</h3>
                        <p>
                            Cashwyre provides the platform for ANYONE in ANY
                            part of the world to provide liquidity in fiat
                            currencies.
                        </p>
                    </div>
                </div>

                <div
                    data-aos="fade-in"
                    data-aos-delay="300"
                    class="
                        col-12 col-md-12 col-lg-4
                        middle
                        order-0 order-md-0 order-lg-2
                    "
                >
                    <div class="square-element">
                        <div class="info">
                            <div class="image">
                                <img
                                    src="@/assets/img/services/partnership.png"
                                    alt="partnership image"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-aos="fade-in"
                    data-aos-delay="400"
                    class="
                        col-12 col-md-6 col-lg-4
                        right
                        order-3 order-md-2 order-lg-3
                    "
                >
                    <div class="sectiontitle-element">
                        <h3>Earn Returns</h3>
                        <p>
                            You earn instantly on every matched requests that is
                            completely fulfilled. <br />
                            Oh…yes, it’s that simple!
                        </p>
                    </div>
                </div>
            </div>

            <div class="find_out_more_container text-center pt-50 pb-50">
                <router-link
                    :to="{ name: 'BecomeLiquidityProvider' }"
                    class="btn--big--border--round"
                    >Learn how to become a partner</router-link
                >
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url(@/assets/scss/blp-intro.scss);

#what-we-do {
    .sectiontitle-element--center,
    .sectiontitle-element {
        h2,
        h3 {
            color: var(--white);
        }

        h3 {
            font-size: 28px;
            text-transform: capitalize;
        }

        p {
            color: var(--grey2);
        }
    }

    .sectiontitle-element p,
    .list-element ul li,
    .list-element ul li:before {
        font-size: 1rem;
        color: var(--grey2);
    }

    .left {
        text-align: right;
    }

    .middle {
        max-width: 300px;
        margin: auto;
        text-align: center;
    }

    .right {
        text-align: left;
    }

    @media screen and (max-width: 991px) {
        .middle {
            max-width: 80%;
        }
        .left {
            border-right: 1px solid var(--grey2);
        }
    }

    @media screen and (max-width: 767px) {
        .left {
            text-align: left;
            border-right: none;
        }
    }
}

[class|="list-element"] ul li {
    margin-bottom: 0;
}

.row.align-items-lg-center:nth-child(2n + 1) .sectiontitle-element {
    margin-left: auto;
}

.info {
    padding: 0 0rem 0.5rem;
}

.row.align-items-lg-center:nth-child(2n) .info {
    margin-left: auto;

    @media screen and (max-width: 768px) {
        margin-right: auto;
    }
}

[class|="rectangle-element"] [class|="info"] [class|="image"] img,
[class|="square-element"] [class|="info"] [class|="image"] img {
    height: initial;
}

img {
    -o-object-position: center;
    object-position: center;
    -o-object-fit: cover;
    object-fit: cover;
}
</style>

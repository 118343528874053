// ########################3       GENERAL && OAUTH        #################################

// import mixins from "@/mixins";
export const REGISTRATION_INFO = (state, data) => {
  state.registrationInfo = data;
};

export const SAVE_COUNTRY_INFO = (state, data) => {
  state.countryInfo = data;
};

export const CONTACT_INFO = (state, data) => {
  state.contactInfo = data;
  // console.log(data);
};

export const CURRENT_SITE_PAGE = (state, payload) => {
  state.currentSitePage = payload;
};

export const SAVE_INVESTMENT = (state, payload) => {
  state.allInvestments = payload;
  // console.log(state.allInvestments);
};

export const SAVE_TESTIMONIES = (state, payload) => {
  state.allTestimonies = payload;
};

// export const SAVE_POSITIVE_FEEDBACKS = (state, payload) => {
//   state.positiveFeedBacks = payload;
// };

// export const SAVE_FEEDBACKS = (state, payload) => {
//   state.feedBacks = payload;
// };

// ########################3       NOTIFICATIONS       #################################

export const SET_ALERT = (state, payload) => {
  // console.log("here");
  state.alertStatus = false;
  state.alertType = "";
  state.alertMessage = "";

  setTimeout(() => {
    state.alertStatus = payload.status;
    state.alertType = payload.type;
    state.alertMessage = payload.message;
  }, 800);

  setTimeout(() => {
    state.alertStatus = false;
    state.alertType = "";
    state.alertMessage = "";
  }, 4500);
};

export const SET_ALERT2 = (state, payload) => {
  // console.log("here");
  state.alert2 = {
    status: payload?.status,
    type: payload?.type,
    message: payload?.message,
  };

  setTimeout(() => {
    state.alert2 = {
      status: false,
      type: "",
      message: "",
    };
  }, 3800);
};

export const SET_VERIFY_ACTION = (state, payload) => {
  // state.verifyPayload = payload.payload;
  state.verifyPayload = {
    status: payload.status,
    type: payload.type,
    title: payload.title,
    des: payload.des,
    payload: payload.payload,
  };
};

// ########################3           LOADERS        #################################

export const SET_ALERT_MODAL_STATUS = (state, payload) => {
  state.alertModalStatus = payload.status;
  state.alertModalType = payload.type;
  state.alertModalMessage = payload.message;
};

export const SET_ACTION_LOADING = (state, payload) => {
  setTimeout(() => {
    state.actionProcessing = payload;
  }, 200);
};

export const SET_MODAL_LOADING = (state, status) => {
  state.modalLoading = status;
};

export const SET_PAGE_PROCESSING = (state, status) => {
  state.pageProcessing = status;
};

export const SET_PAGE_LOADING = (state, payload) => {
  state.pageLoading = {
    status: payload.status,
    message: payload.message,
    payload: payload.payload,
  };
};

// ########################3           MODALS        #################################

export const SET_CARD_MODAL = (state, status) => {
  state.cardModal = status;
};

// export const SET_CALCULATE_MODAL = (state, payload) => {
//   state.calculateInvestmentModal = {
//     status: payload.status,
//     payload: payload.payload,
//   };
// };

// ########################3           SINGLE PROPERTIES        #################################

export const BANNER_SETTINGS = (state, payload) => {
  state.bannerSettings = {
    title: payload.title,
    description: payload.description,
    prevLinkText: payload.prevLinkText,
    currentLinkText: payload.currentLinkText,
    buttonUrl: payload.buttonUrl,
    imageUrl: payload.imageUrl,
  };
};

export const SAVE_COUNTRIES = (state, payload) => {
  state.countries = payload;
};

export const SAVE_COUNTRY_CURRENCIES = (state, payload) => {
  state.countryCurrencies = payload;
};

export const SAVE_CURRENCY_INFO = (state, payload) => {
  state.currencyInfo = payload;
};

export const SAVE_CRYPTO4CASH_INFO = (state, payload) => {
  state.cryto4CashInfo = payload;
};

export const SAVE_ALL_GLOBAL_COUNTRIES = (state, payload) => {
  state.allGlobalCountries = payload;
};

export const CALCULATION_RESULT = (state, payload) => {
  state.calculationResult = payload;
};

export const SET_IS_CALCULATING = (state, payload) => {
  state.isCalculating = payload;
};

export const SAVE_ALL_DATA = (state, payload) => {
  state.allData = payload;
};

export const TOGGLE_SUBSCRIPTION_MODAL = (state, status) => {
  state.modalActive = status;
};

export const TOGGLE_COOKIES_ACTIVE = (state, status) => {
  state.cookiesActive = status;
};

export const IS_COOKIES_SET = (state, status) => {
  state.isCookiesSet = status;
};

<template>
  <div class="whatsappDiv">
    <a href="" @click.prevent="openWhatsapp" class="whatsappDivWrappwe">
      <img src="@/assets/img/services/whatsapp.png" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    openWhatsapp() {
      let url = `whatsapp://send?phone=+2349127287158&text=${"How may we assist you today?"}`;

      window.open(url);
    },
  },
};
</script>

<style>
</style>
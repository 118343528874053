<template>
    <div class="page-content">
        <HomeIntro />
        <!-- <WhatWeDo1 /> -->
        <BecomeLiquidityProvider />
        <CalculateAmount />
        <OurAdvantage />
        <!-- <SeparatorElement /> -->
        <HowItWorks />
        <!-- <Partners /> -->
        <CallToAction />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import "@/mixins";
import $ from "jquery";


import HomeIntro from "../components/home/HomeIntro.vue";
import OurAdvantage from "../components/home/OurAdvantage.vue";
import HowItWorks from "../components/home/HowItWorks.vue";
// import WhatWeDo1 from "../components/home/WhatWeDo1.vue";
import BecomeLiquidityProvider from "../components/home/BecomeLiquidityProvider.vue";
import CalculateAmount from "../components/extra/CalculateAmount.vue";
// import Partners from "../components/about/AllPartners.vue";
// import SeparatorElement from "@/components/extra/SeparatorElement.vue";
import CallToAction from "../components/extra/CallToAction.vue";

export default {
    components: {
        HomeIntro,
        OurAdvantage,
        HowItWorks,
        // WhatWeDo1,
        BecomeLiquidityProvider,
        CalculateAmount,
        // Partners,
        // SeparatorElement,
        CallToAction,
    },

    methods: {
        ...mapActions([
            "setCurrentPage",
            "setJournalModal",
            "setPageLoading",
            "setPostCommentModal",
            "setNoteModal",
        ]),

        addPageStyles() {
            if (document.querySelector(".navbar-placeholder")) {
                $(".navbar").removeClass("is-transparent navbar-light");
                $(".navbar").addClass("navbar-faded");
                $(".navbar-item .button-signup ").removeClass("light-btn");
                $(".navbar-item .button-signup").addClass("primary-btn");
            }
        },
    },

    mounted() {
        this.setCurrentPage(this.$router.history.current.name);
        // this.addPageStyles();

        // $(window).scroll(() => {
        //   this.addPageStyles();
        // });
    },
};
</script>

import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
// import db from "@/components/extras/firebaseInit";

Vue.mixin({
  computed: {
    ...mapGetters([
      "user",
      "AppId",
      "RequestId",
      "currentSitePage",
      "bannerSettings",
    ]),
    ...mapState(["cashwyreURL", "walletURL", "allChats"]),

    getCurrentYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
  methods: {
    ...mapActions([
      "setActionLoading",
      "setAlertModalStatus",
      "setPageLoading",
    ]),

    serverErrorMessage: function () {
      this.setActionLoading(false);
      setTimeout(() => {
        let payload = {
          status: true,
          type: "error",
          message: "Server Error. Please try again later",
        };
        this.setAlertModalStatus(payload);
      }, 200);
    },

    scrollElementToView: function () {
      if (document.querySelector(".ul_chat_windows_ul")) {
        let box = document.querySelector(".ul_chat_windows_ul");
        box.scrollTop = box.scrollHeight;
      }
    },

    globalFunctions: function () {
      if (this.user) {
        // setTimeout(this.globalFunctions, 5000);
      }
    },

    eliminateDuplicateCodes(arr) {
      return arr
        .map((e) => e["groupId"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => arr[obj])
        .map((e) => arr[e]);
    },

    // (?<!\.\d*)(\d)(?=(?:\d{3})+(?:\.|$))

    // addComma(value) {
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },

    addComma(value) {
      let pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b)/g;
      if (value.toString().includes(".")) {
        pattern = /(?=(?!^)\d{3}(?:\b|(?:\d{3})+)\b\.)/g;
      }
      return value.toString().replace(pattern, ",");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    removeFormatDate: function (date) {
      var d = new Date(date);
      let month = d.getMonth() + 1;
      let day = d.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let returnedDate = d.getFullYear() + "-" + month + "-" + day;

      return returnedDate;
    },

    removeTimeZone(datestring) {
      let finalDate = "";

      if (datestring.split("+").length > 1) {
        let b = datestring.split("T");
        finalDate = b[0];
      } else {
        let b = datestring.split("T");

        if (b.length > 1) {
          b.pop();
          finalDate = b.join("-");
        }
      }

      return finalDate;
    },

    validEmail: function (email) {
      // email.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/);

      var emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailRegex.test(email);
    },

    validPhone: function (phone) {
      // var phoneRegex = /^[+][(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/gm;
      var phoneRegex = /^\d{11}$/;
      return phoneRegex.test(phone);
    },

    convertSecondsToDate: function (secs) {
      var d = new Date(secs * 1000);
      return d;
    },

    getLocalTYime: function (data) {
      var d = new Date(data);
      return d.toLocaleTimeString().replace(/:\d+ /, " ");
    },

    geCurrentYear: function () {
      var d = new Date();
      return d.getFullYear();
    },

    getTimeAgo: function (date) {
      var currDate = new Date();
      var diffMs = currDate.getTime() - new Date(date).getTime();
      var sec = diffMs / 1000;
      if (sec < 60)
        return (
          parseInt(sec) + " second" + (parseInt(sec) > 1 ? "s" : "") + " ago"
        );
      var min = sec / 60;
      if (min < 60)
        return (
          parseInt(min) + " minute" + (parseInt(min) > 1 ? "s" : "") + " ago"
        );
      var h = min / 60;
      if (h < 24)
        return parseInt(h) + " hour" + (parseInt(h) > 1 ? "s" : "") + " ago";
      var d = h / 24;
      if (d < 30)
        return parseInt(d) + " day" + (parseInt(d) > 1 ? "s" : "") + " ago";
      var m = d / 30;
      if (m < 12)
        return parseInt(m) + " month" + (parseInt(m) > 1 ? "s" : "") + " ago";
      var y = m / 12;
      return parseInt(y) + " year" + (parseInt(y) > 1 ? "s" : "") + " ago";
    },

    removeUnderScoreFromString: function (str) {
      var i,
        frags = str.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toLowerCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },

    removeDashFromString: function (str) {
      var i,
        frags = str.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },

    formatDate: function (date) {
      var d = new Date(date);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      let returnedDate;

      var newD = new Date();

      let userdate =
        d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      let todaysdate =
        newD.getDate() + "/" + (newD.getMonth() + 1) + "/" + newD.getFullYear();

      if (userdate === todaysdate) {
        returnedDate = strTime + ", Today";
      } else {
        returnedDate =
          d.getDate() +
          "/" +
          (d.getMonth() + 1) +
          "/" +
          d.getFullYear() +
          "  " +
          strTime;
      }

      return returnedDate;
    },
    formatDateRemoveText: function (date) {
      var d = new Date(date);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      let returnedDate;

      var newD = new Date();

      let userdate =
        d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      let todaysdate =
        newD.getDate() + "/" + (newD.getMonth() + 1) + "/" + newD.getFullYear();

      if (userdate === todaysdate) {
        returnedDate = strTime;
      } else {
        returnedDate =
          d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
      }

      return returnedDate;
    },
    formateDateNTime: function (date) {
      var d = new Date(date);
      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      let returnedDate;

      returnedDate =
        d.getDate() +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        "  " +
        strTime;

      return returnedDate;
    },

    formateDateByName: function (newDate) {
      const d = new Date(newDate);
      const year = d.getFullYear(); // 2019
      const date = d.getDate();
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const dayIndex = d.getDay();
      const dayName = days[dayIndex];

      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];

      const formatted = `${dayName}, ${date} ${monthName} ${year}`;

      return formatted;
    },

    formateDateAndTimeByName: function (newDate) {
      const d = new Date(newDate);
      const year = d.getFullYear(); // 2019
      const date = d.getDate();
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var hours = d.getHours();
      var minutes = d.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;

      const dayIndex = d.getDay();
      const dayName = days[dayIndex];

      const monthIndex = d.getMonth();
      const monthName = months[monthIndex];

      const formatted = `${dayName}, ${date} ${monthName} ${year}, ${strTime}`;

      return formatted;
    },

    shuffleArray: function (array) {
      var ctr = array.length,
        temp,
        index;
      while (ctr > 0) {
        index = Math.floor(Math.random() * ctr);
        ctr--;
        temp = array[ctr];
        array[ctr] = array[index];
        array[index] = temp;
      }
      return array;
    },

    kFormatter: function (num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    copyCode: function (codeToCopy) {
      var input = document.createElement("input");
      input.setAttribute("value", codeToCopy);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);
      return result;
    },

    sortArrayname: function (array) {
      let newArray = array.sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });

      return newArray?.length ? newArray : [];
    },

    formatSeconds: function (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      // Ensure minutes and seconds are two digits
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    removeCommaAndConvertToNumber: function (str) {
      // Remove the comma
      const numberWithoutComma = str.replace(/,/g, "");
      // Convert the string to a number
      const number = parseFloat(numberWithoutComma);
      return number;
    },
  },
});

<template>
  <!-- "page-header--image--round" data-overlay -->
  <div class="page-header--image" data-padding data-overlay>
    <div class="imagebg">
      <img src="@/assets/img/banner/banner-world.jpg" alt="hero background" />
    </div>

    <div class="container">
      <div class="row">
        <!-- <div data-aos="fade-up" class="col-12 col-md-11 col-lg-8"> -->
        <div data-aos="fade-up" class="col-12 col-md-11 col-lg-8 col-xl-8">
          <div class="sectiontitle-element--light">
            <div v-if="changed === 1">
              <h1 class="big">
                Send and Receive Money
                <span>freely</span> across borders
              </h1>
              <p>No hidden charges and no subscription needed.</p>
            </div>
            <div v-if="changed === 2">
              <h1 class="big">
                Get paid in <span>Crypto</span> & withdraw as fiat/cash
              </h1>
              <p>
                Hassle-free transactions using the Bitcoin & Crypto network.
              </p>
            </div>
            <div v-if="changed === 3">
              <h1 class="big">
                Swap your Crypto for cash with <span>Crypto4Cash</span>
              </h1>
              <p>Send crypto and receive local currencies in minutes</p>
            </div>
            <div v-if="changed === 4">
              <h1 class="big">
                Shop Globally With <span>Cashwyre Dollar Card</span>
              </h1>
              <p>
                Cashwyre Dollar Card, the trust and secured way to shop
                internally
              </p>
            </div>

            <div class="app-stores">
              <a
                :href="allData ? allData.iosAppURL : null"
                target="_blank"
                rel="noopener noreferrer"
                class="btn--big--round--border--light"
                title="App Store"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25.42 29.39"
                  width="16"
                  height="20"
                  fill="#fff"
                >
                  <title>App Store</title>
                  <path d="M20.55,0a7,7,0,0,1-7,7A7,7,0,0,1,20.55,0Z"></path>
                  <path
                    class="cls-1"
                    d="M19.44,15.55a5.66,5.66,0,0,1,4.29-5.2,8.37,8.37,0,0,0-6.45-3.1,8.46,8.46,0,0,0-3.4.93,2.17,2.17,0,0,1-1.83,0,8.54,8.54,0,0,0-3.41-.93C3.87,7.25,0,11.38,0,16.47,0,23.6,3.38,29.39,7.56,29.39A13,13,0,0,0,12,28.27a2.6,2.6,0,0,1,1.9,0,13,13,0,0,0,4.45,1.12c3.23,0,6-3.46,7.06-8.32C22.08,20.85,19.44,18.46,19.44,15.55Z"
                  ></path>
                </svg>
                App Store
                <!-- <span v-if="allData ? allData.iosAppURL : null"
                                    >App Store</span
                                >
                                <span v-else>Coming Soon</span> -->
              </a>
              <a
                :href="allData ? allData.googleAppURL : null"
                target="_blank"
                rel="noopener noreferrer"
                class="btn--big--round--border--light ml-0"
                title="Google Play"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 27"
                  width="16"
                  height="20"
                  fill="#fff"
                >
                  <title>Google Play</title>
                  <path
                    d="M16.1545 12.3557L4.51606 0.685547L19.3239 9.18633L16.1545 12.3557ZM1.47856 0C0.793018 0.358594 0.334229 1.0125 0.334229 1.86152V25.1332C0.334229 25.9822 0.793018 26.6361 1.47856 26.9947L15.0102 13.4947L1.47856 0ZM23.9012 11.8969L20.7952 10.0986L17.3305 13.5L20.7952 16.9014L23.9645 15.1031C24.9137 14.349 24.9137 12.651 23.9012 11.8969ZM4.51606 26.3145L19.3239 17.8137L16.1545 14.6443L4.51606 26.3145Z"
                  ></path>
                </svg>
                Google Play
                <!-- <span v-if="allData ? allData.iosAppURL : null"
                                    >Google Play</span
                                >
                                <span v-else>Coming Soon</span> -->
              </a>
            </div>
          </div>
        </div>

        <!-- <div
                    data-aos="fade-up"
                    class="col-12 col-md-11 col-lg-4 col-xl-5 right"
                >
                    <div class="imagebg">
                        <img
                            src="@/assets/img/banner/hero-new.jpeg"
                            alt="hero background"
                        />
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      changed: 1,
      countDown: undefined,
      // walletItems: {
      //     sectionTitle: "Send and receive money freely across borders",
      //     sectionDescription:
      //         "Send and receive money freely, easily, and securely across borders",
      // },

      // bitcoinItems: {
      //     sectionTitle: "Bitcoin banking/savings made easy",
      //     sectionDescription:
      //         "Send, Receive, Save & Custody (in-app and self-custody) Bitcoin without stress",
      // },
    };
  },

  computed: {
    ...mapState(["allData"]),
  },

  methods: {
    changeTexts() {
      if (this.changed === 4) {
        this.changed = 1;
        return;
      }
      this.changed = this.changed + 1;
    },
  },

  beforeMount() {
    this.changed = 1;
  },

  beforeDestroy() {
    clearInterval(this.countDown);
  },

  mounted() {
    this.countDown = setInterval(() => {
      this.changeTexts();
    }, 5500);
  },
};
</script>

<style lang="scss" scoped>
[class|="page-header"] {
  p {
    color: var(--grey2);
  }

  .sectiontitle-element--light {
    z-index: 2;
  }
}

[class|="page-header"][class*="bg"],
[class|="page-header"][class*="image"] {
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 230px;
}

[class|="page-header"] [class*="imagebg"] {
  z-index: 0;
}

[class|="page-header"][data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

[class|="page-header"][class*="image"][class*="round"]:after {
  background: var(--orangeDark);
}

[class|="page-header"] [class*="imagebg"] img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

h1 span {
  color: var(--orangeDark);
  display: inline;
}

.big {
  margin-bottom: 1rem;
}

.app-stores {
  margin-top: 2.25rem;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;

    svg {
      margin-right: 6px;
    }

    &:hover {
      color: var(--orangeLight);
    }

    &:hover svg {
      fill: var(--orangeLight);
    }

    &:first-child {
      background: var(--orangeDark) !important;
      border-color: var(--orangeDark) !important;

      &:hover {
        background: var(--white) !important;
        border-color: var(--white) !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .right {
    display: none;
  }
}
</style>

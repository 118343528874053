<template>
    <section id="about-us" data-padding="smtop">
        <div class="container">
            <div data-aos="fade-up" class="row pb-30">
                <div class="col-12">
                    <div class="ctabox-element--center--round">
                        <div class="sectiontitle-element--center">
                            <!-- <span class="toptitle">With Cashwyre</span> -->
                            <h2>Make payments with Bitcoin globally</h2>

                            <p class="pb-0">
                                Cashwyre simplifies and improves lives by
                                integrating Bitcoin into your daily life. Thanks
                                to our platform, you can send and receive money
                                globally, pay school fees, get your salary
                                income, save in Bitcoin and lots more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div
                    v-for="(service, index) in services"
                    :key="index"
                    data-aos="fade-up"
                    :data-aos-delay="(index + 1) * 80"
                    class="col-12 col-md-6"
                >
                    <div class="ctabox-element--center--round">
                        <div class="sectiontitle-element">
                            <div class="icon-element">
                                <div class="info">
                                    <div class="icon">
                                        <i :class="`icon ${service.icon}`"></i>
                                    </div>

                                    <h3>{{ service.title }}</h3>

                                    <div class="list-element">
                                        <ul>
                                            <li
                                                v-for="(
                                                    item, index
                                                ) in service.listItems"
                                                :key="index"
                                            >
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="learn-more">
                                        <router-link
                                            :to="{ name: service.routeName }"
                                            class="simple--dotted"
                                        >
                                            Learn more
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CountriesSupported />
        </div>
    </section>
</template>

<script>
import CountriesSupported from "@/components/extra/CountriesSupported.vue";

export default {
    components: {
        CountriesSupported,
    },

    data() {
        return {
            services: [
                {
                    icon: "ti-wallet",
                    title: "Our Payment Services",
                    listItems: [
                        "Pay for tuition/school fees abroad.",
                        "Receive money and paychecks.",
                        "Send money back home to family members and loved ones.",
                    ],
                    routeName: "PaymentServices",
                },
                {
                    icon: "ti-layers-alt",
                    title: "Our Wallet Service",
                    listItems: [
                        "Get paid in Bitcoin (onchain and lightning) seamlessly.",
                        "Save in Bitcoin, as little as $1 at varying frequencies.",
                        "Pay anyone instantly with Bitcoin and Stablecoins.",
                    ],
                    routeName: "WalletServices",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
#about-us {
    [class|="ctabox-element"] {
        background: linear-gradient(
            to bottom right,
            var(--black2),
            var(--black2)
        );
    }

    .sectiontitle-element--center,
    .sectiontitle-element {
        h2,
        h3 {
            text-transform: capitalize;
            color: var(--white);
        }
    }

    .sectiontitle-element .find_out_more_container a {
        color: var(--white) !important;
        border-color: var(--white) !important;
    }

    [class|="sectiontitle-element"] p,
    .list-element ul li,
    .list-element ul li:before {
        color: var(--grey2);
    }

    [class|="icon-element"] {
        .info {
            -ms-flex-direction: column;
            flex-direction: column;
            padding-bottom: 0;

            & > .icon {
                padding: 0 0rem 20px;

                i {
                    font-size: 2.5rem;
                }
            }

            h3 {
                font-size: 20px;
                padding-bottom: 15px;
            }

            .list-element ul li {
                // margin-bottom: 0;
                padding-top: 5px;

                // &::before {
                //     top: 12px;
                //     height: 18px;
                //     width: 18px;
                //     font-size: 14px;
                //     line-height: 18px;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     background: var(--orangeLight);
                //     border-radius: 100%;
                // }
            }

            .learn-more {
                margin-top: 25px;
                text-align: right;

                a {
                    max-width: max-content;
                    color: var(--orangeLight);

                    &:hover {
                        color: var(--white);
                    }
                }
            }
        }
    }

    [class|="sectiontitle-element"] p + [class|="list-element"] {
        padding-top: 20px;
    }

    @media screen and (max-width: 767px) {
        .row.pb-30 {
            padding-bottom: 48px;
        }
    }
}
</style>

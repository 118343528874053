<template>
    <footer id="footer" data-aos="fade">
        <div class="container">
            <FooterTop />
            <FooterBottom />
        </div>
    </footer>
</template>

<script>
import FooterTop from "./FooterTop.vue";
import FooterBottom from "./FooterBottom.vue";
export default {
    components: {
        FooterTop,
        FooterBottom,
    },
};
</script>

<style scoped>
</style>

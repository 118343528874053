import { render, staticRenderFns } from "./CallToAction.vue?vue&type=template&id=1c374c80&scoped=true"
import script from "./CallToAction.vue?vue&type=script&lang=js"
export * from "./CallToAction.vue?vue&type=script&lang=js"
import style0 from "./CallToAction.vue?vue&type=style&index=0&id=1c374c80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c374c80",
  null
  
)

export default component.exports
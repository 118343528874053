var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"calculate-amount","data-aos":"fade","data-padding":"sm"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"ctabox-element--input--round"},[_vm._m(0),_c('div',{staticClass:"cta"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.calculateAmount.apply(null, arguments)}}},[_c('div',{staticClass:"newsletter-form--round--right--secondary--v2 mb-10"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.sendAmount),expression:"sendAmount",modifiers:{"number":true}}],attrs:{"type":"text","name":"sendAmount","id":"sendAmount","placeholder":"Send Amount"},domProps:{"value":(_vm.sendAmount)},on:{"input":[function($event){if($event.target.composing)return;_vm.sendAmount=_vm._n($event.target.value)},_vm.checkSendAmount],"blur":function($event){return _vm.$forceUpdate()}}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendCurrency),expression:"sendCurrency"}],staticClass:"submit-wrap currency",class:_vm.sendCurrency && _vm.isSelected
                                            ? 'selected'
                                            : '',attrs:{"name":"sendCurrency","id":"sendCurrency"},on:{"input":_vm.handleSelect,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sendCurrency=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Select send currency ")]),_vm._l((_vm.allCurrencyInfo.sendCountryCurrencies),function(sendCurrency){return _c('option',{key:`${sendCurrency.countryCode}-${sendCurrency.currencyCode}`,domProps:{"value":sendCurrency.currencyCode}},[_c('span',[_vm._v(" "+_vm._s(sendCurrency.countryName)+" ")])])})],2)]),_c('div',{staticClass:"newsletter-form--round--right--secondary--v2 mb-10"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.receiveAmount),expression:"receiveAmount",modifiers:{"number":true}}],attrs:{"type":"text","name":"receiveAmount","id":"receiveAmount","placeholder":"Receive Amount (optional)"},domProps:{"value":(_vm.receiveAmount)},on:{"input":[function($event){if($event.target.composing)return;_vm.receiveAmount=_vm._n($event.target.value)},_vm.checkReceiveAmount],"blur":function($event){return _vm.$forceUpdate()}}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.receiveCurrency),expression:"receiveCurrency"}],staticClass:"submit-wrap currency",class:_vm.receiveCurrency && _vm.isSelected
                                            ? 'selected'
                                            : '',attrs:{"name":"receiveCurrency","id":"receiveCurrency"},on:{"input":_vm.handleSelect,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.receiveCurrency=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Select receive currency ")]),_vm._l((_vm.allCurrencyInfo.receiveCountryCurrencies),function(receiveCurrency){return _c('option',{key:`${receiveCurrency.countryCode}-${receiveCurrency.currencyCode}`,domProps:{"value":receiveCurrency.currencyCode}},[_c('span',[_vm._v(" "+_vm._s(receiveCurrency.countryName)+" ")])])})],2)]),_c('div',{staticClass:"newsletter-form--round--right text-right"},[_c('button',{staticClass:"btn--big--round",attrs:{"type":"submit","disabled":!_vm.isValid}},[(!_vm.isLoading)?_c('span',[_vm._v("Calculate")]):_c('span',{staticClass:"fas fa-spinner fa-pulse",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])]),(_vm.result !== null && _vm.isResultAvailable)?_c('div',{staticClass:"calculation-result"},[_c('div',{staticClass:"destinations"},[_c('p',{staticClass:"sender"},[_vm._v(" Send Amount: "),_c('span',[_vm._v(_vm._s(_vm.result.sendCurrency)+" "+_vm._s(_vm.addComma( _vm.result.sendAmount.toFixed(0) )))])]),_c('p',{staticClass:"receiver"},[_vm._v(" Receive Amount: "),_c('span',[_vm._v(_vm._s(_vm.result.receiveCurrency)+" "+_vm._s(_vm.addComma( _vm.result.receiveAmount.toFixed( 0 ) )))])]),_c('p',{staticClass:"amount-entered"},[_vm._v(" Rate: "),_c('span',[_vm._v(" "+_vm._s(_vm.result.sendRateInfo)+" ")])])]),_c('div',{staticClass:"result"},[_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.result.description)+" ")])])])]):_vm._e(),(
                                !_vm.allCurrencyInfo ||
                                (_vm.setModalMessage && !_vm.setModalStatus)
                            )?_c('div',{staticClass:"calculation-result error"},[_c('p',[_vm._v(_vm._s(_vm.setModalMessage))])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h2',[_vm._v("Calculate Send or Receive Amount")]),_c('p',[_vm._v(" To calculate, kindly enter the Send or Receive Amount and select the currencies, the system will estimate what to expect if you proceed with the transaction immediately. "),_c('a',{staticClass:"simple--dotted",attrs:{"href":"#download-app","rel":"noopener noreferrer"}},[_vm._v("Send money freely today!")])])])
}]

export { render, staticRenderFns }